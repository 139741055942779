<template>
  <section v-loading="loading" class="page-container">
    <div class="page-section config-panel">
      <el-tabs value="commonConfigTab">
        <el-tab-pane label="通用配置" name="commonConfigTab">
          <el-form label-width="160px">
            <el-form-item label="服务商PID">
              <el-input
                v-model="config.servicePid"
                type="text"
                placeholder="服务商PID"
              />
            </el-form-item>

            <el-form-item label="小程序AppId">
              <el-input
                v-model="config.miniAppId"
                type="text"
                autosize
                placeholder="小程序AppId"
              />
            </el-form-item>

            <el-form-item label="第三方AppId">
              <el-input
                v-model="config.thirdPartyAppId"
                type="text"
                autosize
                placeholder="第三方AppId"
              />
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div style="text-align: right">
        <el-button :loading="loading" type="primary" @click="save">
          保存配置
        </el-button>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: { draggable },
  data() {
    return {
      config: {},
      loading: false
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        this.config = await this.axios.get('/api/admin/sys-config/all')
      } catch (err) {
        this.$notify.error({ title: '错误', message: err.message })
      } finally {
        this.loading = false
      }
    },
    async save() {
      this.loading = true
      try {
        await this.axios.form('/api/admin/sys-config/save', {
          config: JSON.stringify(this.config)
        })
        this.$message({ message: '提交成功', type: 'success' })
        this.load()
      } catch (err) {
        this.$notify.error({ title: '错误', message: err.message })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.config-panel {
  // margin: 20px;
  padding: 10px;
}

.config {
  padding: 10px 0;
}

.nav-panel {
  .navs {
    border: 1px solid #ddd;
    border-radius: 5px;

    .nav {
      padding: 5px 5px;
      margin: 0;

      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      .nav-sort-btn {
        font-size: 21px;
        font-weight: 700;
        cursor: pointer;
        float: right;
      }
    }
  }

  .add-nav {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
